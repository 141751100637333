<template>
  <div
    class="
      d-flex
      flex-column flex-column-fluid
      bgi-position-y-bottom
      position-x-center
      bgi-no-repeat bgi-size-contain bgi-attachment-fixed
    "
    style="background-image: url('media/illustrations/sketchy-1/14.png')"
  >
    <div class="d-flex flex-center flex-column flex-column-fluid p-10">
      <a href="#" class="mb-12">
        <img alt="Logo" src="/media/images/Discovery_Logo-top.png" class="h-30px" />
      </a>

      <router-view></router-view>
    </div>

    <div class="d-flex flex-center flex-column-auto pb-10">
      <div class="d-flex align-items-center fw-bold fs-6">
        <p class="fs-6 fw-bolder px-2">Please contact the student management system administratior if you do not have user credentials</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "auth",
  components: {},
  setup() {
    const store = useStore();

    onMounted(() => {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
    });

    onUnmounted(() => {
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
    });
  },
});
</script>
